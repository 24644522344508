import { type SanityClient } from '@sanity/client'

import {
  allSlugsQuery,
  crawlingBlockedPagesQuery,
  customPageQuery,
  errorPageQuery,
  homePageQuery,
  sitemapPagesQuery,
  sitemapStaticPagesQuery,
} from '@data/sanity/queries/page'
import {
  type SanityAllSlugs,
  type SanityCrawlingBlockedPagesQuery,
  type SanityCustomPageQuery,
  type SanityErrorPageQuery,
  type SanityHomePageQuery,
  type SanitySitemapPagesQuery,
  type SanitySitemapStaticPagesQuery,
} from '@data/sanity/queries/types/page'
import {
  type SanityBlogAuthorSlugQueryParameter,
  type SanityBlogCategorySlugQueryParameter,
  type SanityBlogPostSlugQueryParameter,
  type SanityLocaleQueryParameter,
  type SanityLocalesQueryParameter,
  type SanityPageSlugsQueryParameter,
  type SanityTypeQueryParameter,
  type SanityTypesQueryParameter,
} from '@data/sanity/queries/types/parameters'
import { fetchQuery } from './client'
import { type PageType } from '@lib/routes'

export type SanityModuleQueryParameters = SanityBlogAuthorSlugQueryParameter &
  SanityBlogCategorySlugQueryParameter &
  SanityBlogPostSlugQueryParameter

export const defaultModuleQueryParameters: SanityModuleQueryParameters = {
  blogAuthorSlug: null,
  blogCategorySlug: null,
  blogPostSlug: null,
}

/**
 * Fetches all document slugs in all locales.
 */
export const getAllSlugs = async (sanityClient: SanityClient, type: string) =>
  fetchQuery<SanityAllSlugs, SanityTypeQueryParameter>(
    sanityClient,
    allSlugsQuery,
    {
      type,
    }
  )

/**
 * Fetches pages for sitemap.
 */
export const getSitemapPages = async (
  sanityClient: SanityClient,
  type: PageType,
  locales: string[]
) =>
  fetchQuery<
    SanitySitemapPagesQuery,
    SanityTypeQueryParameter & SanityLocalesQueryParameter
  >(sanityClient, sitemapPagesQuery, {
    locales,
    type,
  })

/**
 * Fetches static pages for sitemap.
 */
export const getSitemapStaticPages = async (
  sanityClient: SanityClient,
  locales: string[]
) =>
  fetchQuery<
    SanitySitemapStaticPagesQuery,
    SanityLocalesQueryParameter & SanityTypesQueryParameter
  >(sanityClient, sitemapStaticPagesQuery, {
    locales,
    types: ['blogPage', 'homePage'] satisfies PageType[],
  })

/**
 * Fetches crawling-blocked pages for robots.
 */
export const getCrawlingBlockedPages = async (sanityClient: SanityClient) =>
  fetchQuery<SanityCrawlingBlockedPagesQuery>(
    sanityClient,
    crawlingBlockedPagesQuery
  )

/**
 * Fetches the home page.
 */
export const getHomePage = async (sanityClient: SanityClient, locale: string) =>
  fetchQuery<
    SanityHomePageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, homePageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches the error page.
 */
export const getErrorPage = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<
    SanityErrorPageQuery,
    SanityLocaleQueryParameter & SanityModuleQueryParameters
  >(sanityClient, errorPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
  })

/**
 * Fetches a specific page based on slug.
 */
export const getPage = async (
  sanityClient: SanityClient,
  locale: string,
  slug: string
) =>
  fetchQuery<
    SanityCustomPageQuery,
    SanityLocaleQueryParameter &
      SanityPageSlugsQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, customPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    pageSlugs: [`/${slug}`, slug, `/${slug}/`],
  })
