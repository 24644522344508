import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { videoFragment } from './video'

const markDefFragment = groq`
  ...,
  _type == "link" => {
    page->{
      ${linkPageFragment}
    },
  },
`

export const simplePortableTextFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
`

const videoButtonFragment = groq`
  thumbnail {
    ${imageFragment}
  },
  video {
    ${videoFragment}
  },
`

const infoBoxFragment = groq`
  content[] {
    ${simplePortableTextFragment}
  },
`

export const complexPortableTextFragment = groq`
  ...,
  markDefs[] {
    ${markDefFragment}
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == "videoButton" => {
    ${videoButtonFragment}
  },
  _type == "infoBox" => {
    ${infoBoxFragment}
  },
`
