import groq from 'groq'

import { cartSettingsFragment } from './cart'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { imageFragment } from './image'
import { linkFragment, linkPageFragment } from './link'

const headerDesktopMenuFragment = groq`
  slug,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerMobileMenuFragment = groq`
  slug,
  items[] {
    ${linkFragment}
    dropdownItems[] {
      ${linkFragment}
    },
  },
`

const headerPromoFragment = groq`
  ...,
  link->{
    ${linkPageFragment}
  },
`

const headerFragment = groq`
  menuDesktopLeft->{
    ${headerDesktopMenuFragment}
  },
  menuDesktopRight->{
    ${headerDesktopMenuFragment}
  },
  menuMobilePrimary->{
    ${headerMobileMenuFragment}
  },
  menuMobileSecondary->{
    ${headerMobileMenuFragment}
  },
  "promo": *[
    _type == "promoSettings" &&
    locale == $locale
  ][0] {
    ${headerPromoFragment}
  },
`

export const newsletterFragment = groq`
  ...,
  successMsg[] {
    ${complexPortableTextFragment}
  },
  errorMsg[] {
    ${complexPortableTextFragment}
  },
  terms[] {
    ${simplePortableTextFragment}
  },
`

const footerFragment = groq`
  ...,
  contentBlocks[] {
    title,
    content[] {
      ${simplePortableTextFragment}
    }
  },
  workingHours {
    title,
    items[] {
      day,
      hours
    }
  },
  menuBlocks[] {
    title,
    menu->{
      items[] {
        ${linkFragment}
      },
    },
  },
  additionalMenu->{
    items[] {
      ${linkFragment}
    },
  },
`

const schemaSettingsFragment = groq`
  organization {
    ...,
    menuPage->{
      ${linkPageFragment}
    },
  },
`

const cookieConsentFragment = groq`
  ...,
  link->{
    ${linkPageFragment}
  },
`

const siteStringsFragment = groq`
  loadingPageTitle,
  languageSwitch,
  skipToContent,
  goHomeLabel,
  mobileMenuLabel,
  carouselCounterText,
  carouselLeftArrowLabel,
  carouselRightArrowLabel,
  carouselDotLabel,
  buttonAccept,
  buttonLearnMore,
  buttonSubmit,
  buttonTryAgain,
  buttonLoadMore,
  emailAddress,
  emailAddressPlaceholder,
  emailMissing,
  emailInvalid,
  firstName,
  firstNamePlaceholder,
  firstNameMissing,
  lastName,
  lastNamePlaceholder,
  lastNameMissing,
  fullName,
  fullNamePlaceholder,
  fullNameMissing,
  phoneNumber,
  phoneNumberPlaceholder,
  phoneNumberMissing,
  phoneNumberInvalid,
  company,
  companyPlaceholder,
  message,
  messagePlaceholder,
  messageMissing,
  blogPostReadMore,
  blogPostBy,
`

const identityFragment = groq`
  logo {
    ${imageFragment}
  },
  invertedLogo {
    ${imageFragment}
  },
`

// Only settings that are not secret and can be used in the frontend
const publicSettingsFragment = groq`
  siteURL,
  lifePeaksAccountName,
  klaviyoAccountID,
  gtmContainerId,
  analyticsId,
  facebookEvents,
  facebookPixelId,
  facebookDomainVerification,
  facebookTestEventCode,
  cookieBotId,
  pageAnimation,
`

export const publicSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  }
`

export const allSiteSettingsQuery = groq`
  *[
    _type == "generalSettings" &&
    locale == $locale
  ][0]
`

export const siteFragment = groq`
  "generalSettings": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0] {
    ${publicSettingsFragment}
  },
  "identitySettings": *[
    _type == "identitySettings" &&
    locale == $locale
  ][0] {
    ${identityFragment}
  },
  "cartSettings": *[
    _type == "cartSettings" &&
    locale == $locale
  ][0] {
    ${cartSettingsFragment}
  },
  "blogSettings": *[
    _type == "blogSettings" &&
    locale == $locale
  ][0],
  "cookieSettings": *[
    _type == "cookieSettings" &&
    locale == $locale
  ][0] {
    ${cookieConsentFragment}
  },
  "headerSettings": *[
    _type == "headerSettings" &&
    locale == $locale
  ][0] {
    ${headerFragment}
  },
  "footerSettings": *[
    _type == "footerSettings" &&
    locale == $locale
  ][0] {
    ${footerFragment}
  },
  "seoSettings": *[
    _type == "seoSettings" &&
    locale == $locale
  ][0],
  "schemaSettings": *[
    _type == "schemaSettings" &&
    locale == $locale
  ][0] {
    ${schemaSettingsFragment}
  },
  "strings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${siteStringsFragment}
  },
  "publicLocales": *[
    _type == "generalSettings" &&
    public == true
  ][].locale,
`

export const siteQuery = groq`
  {
    ${siteFragment}
  }
`
