import dynamic from 'next/dynamic'

import { type SanityModule } from '@data/sanity/queries/types/modules'

const BlogPostBody = dynamic(() => import('./blog/blog-post-body'))
const BlogPostGrid = dynamic(() => import('./blog/blog-post-grid'))
const BlogPostHeader = dynamic(() => import('./blog/blog-post-header'))
const BlogPostReadMore = dynamic(() => import('./blog/blog-post-read-more'))
const DividerLogo = dynamic(() => import('./divider-logo'))
const DividerPhoto = dynamic(() => import('./divider-photo'))
const Gallery = dynamic(() => import('./gallery'))
const GiftCardForm = dynamic(() => import('./gift-card-form'))
const Grid = dynamic(() => import('./grid'))
const Hero = dynamic(() => import('./hero'))
const Marquee = dynamic(() => import('./marquee'))
const PricingPlans = dynamic(() => import('./pricing-plans'))
const RestaurantCourses = dynamic(() => import('./restaurant-courses'))
const VideoModule = dynamic(() => import('./video'))

interface ModuleProps {
  module: SanityModule
}

const Module = ({ module }: ModuleProps) => {
  switch (module._type) {
    case 'blogAuthorPostGrid': {
      const { posts, options } = module

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogCategoryPostGrid': {
      const { posts, options } = module

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogPostBody': {
      const { content } = module

      return <BlogPostBody content={content} />
    }

    case 'blogPostGrid': {
      const { posts, options } = module

      return <BlogPostGrid posts={posts} options={options} />
    }

    case 'blogPostHeader': {
      const { post, options } = module

      if (!post) {
        return null
      }

      return <BlogPostHeader post={post} options={options} />
    }

    case 'blogPostReadMore': {
      const { posts } = module

      return <BlogPostReadMore posts={posts} />
    }

    case 'dividerLogo': {
      const { logo } = module

      return <DividerLogo logo={logo} />
    }

    case 'dividerPhoto': {
      const { photo } = module

      return <DividerPhoto photo={photo} />
    }

    case 'gallery': {
      const { title, items } = module

      return <Gallery title={title} items={items} />
    }

    case 'giftCardForm': {
      const {
        heading,
        text,
        values,
        defaultValue,
        minusButtonLabel,
        plusButtonLabel,
        submitButtonLabel,
      } = module

      return (
        <GiftCardForm
          heading={heading}
          text={text}
          values={values}
          defaultValue={defaultValue}
          minusButtonLabel={minusButtonLabel}
          plusButtonLabel={plusButtonLabel}
          submitButtonLabel={submitButtonLabel}
        />
      )
    }

    case 'grid': {
      const {
        columns,
        size,
        spacing,
        background,
        noColumnGaps,
        noRowGaps,
        reverseSequence,
      } = module

      return (
        <Grid
          columns={columns}
          size={size}
          spacing={spacing}
          background={background}
          noColumnGaps={noColumnGaps}
          noRowGaps={noRowGaps}
          reverseSequence={reverseSequence}
        />
      )
    }

    case 'hero': {
      const { bgType, contentPosition, content, photos, muxVideo } = module

      return (
        <Hero
          bgType={bgType}
          contentPosition={contentPosition}
          content={content}
          photos={photos}
          muxVideo={muxVideo}
          overlay
        />
      )
    }

    case 'marquee': {
      const { content, contentAlignment, items, speed, reverse, pausable } =
        module

      return (
        <Marquee
          content={content}
          contentAlignment={contentAlignment}
          items={items}
          speed={speed ?? 0.5}
          reverse={reverse}
          pausable={pausable}
        />
      )
    }

    case 'predefinedPageModule': {
      const { pageModules } = module

      return (
        <>
          {pageModules?.map((pageModule) => (
            <Module key={pageModule._key} module={pageModule} />
          ))}
        </>
      )
    }

    case 'pricingPlans': {
      const { plans } = module

      return <PricingPlans plans={plans} />
    }

    case 'restaurantCourses': {
      const { heading, courses, strings } = module

      return (
        <RestaurantCourses
          heading={heading}
          courses={courses}
          strings={strings}
        />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio, borderRadius } = module

      return (
        <VideoModule
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default Module
